<template>
  <div class="nickName">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">昵称</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="name">
        当前昵称：
        <span>{{ currentName }}</span>
      </div>
      <div class="input">
        <van-field
          class="nickNameInput"
          clearable
          :border="false"
          input-align="left"
          v-model="nameVal"
          placeholder="请输入昵称"
          @input="changeInput"
        />
      </div>
      <div class="confirm" @click="modifyInfo">确认</div>
    </div>
    <div class="tip">每月仅能修改一次，不能二次更改</div>
  </div>
</template>

<script>
import { Field, Toast } from 'vant';
import { modifyPersonInfo } from '@/api/user';
export default {
  name: 'NickName',
  components: {
    [Field.name]: Field,
  },
  data() {
    return {
      nameVal: '',
      maxlength: 9,
      showPoptip: false,
    };
  },
  computed: {
    currentName() {
      return this.$route.query.name;
    },
  },
  methods: {
    async modifyInfo() {
      if (!this.nameVal.trim()) return Toast('用户名不能为空！');
      let req = {
        name: this.nameVal,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(modifyPersonInfo, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          Toast('修改成功');
          this.$router.go(-1);
        } else {
          Toast(res.tip || '修改失败，请稍后再试');
        }
      } catch (error) {
        Toast('操作失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    changeInput() {
      if (this.nameVal.length > this.maxlength) {
        this.nameVal = this.nameVal.slice(0, this.maxlength);
      }
      if (this.nameVal.length === this.maxlength) {
        this.showPoptip = true;
        setTimeout(() => {
          this.showPoptip = false;
        }, 1500);
        // Toast({message:"最多只能输入" + this.maxlength + "个字符",duration:0})
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nickName {
  box-sizing: border-box;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;
      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .main {
    background-color: #fff;
    margin: 16px 12px 0;
    padding: 16px 14px 30px;
    border-radius: 4px;
    .name {
      font-size: 16px;
      span {
        color: rgb(255, 103, 143);
      }
    }
    .nickNameInput {
      margin: 40px 0;
      padding-left: 0;
      padding-right: 0;
      border-bottom: 1px solid rgb(203, 213, 222);
      /deep/ .van-field__word-limit {
        color: #ffffff50;
        font-size: 16px;
      }
      /deep/ .van-field__control {
        color: rgb(153, 153, 153);
        font-size: 16px;
      }
      ::placeholder {
        color: rgb(153, 153, 153);
        font-size: 14px;
      }
    }
    .confirm {
      width: 100%;
      height: 38px;
      background-color: rgb(255, 103, 143);
      text-align: center;
      color: #fff;
      line-height: 38px;
      border-radius: 4px;
    }
  }
  .tip {
    font-size: 14px;
    color: rgb(255, 103, 143);
    margin: 16px 0 0 12px;
  }
}
</style>
